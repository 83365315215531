.select2-container {
    .select2-selection--single {
        height: calc(1.5em + 0.75rem + 2px);

        .select2-selection__rendered {
            display: block;
            padding-left: 8px;
            padding-right: 20px;
        }
    }

    &[dir='rtl'] .select2-selection--single .select2-selection__rendered {
        padding-right: 8px;
        padding-left: 20px;
    }

    .select2-selection--multiple {
        min-height: 32px;

        .select2-selection__rendered {
            padding-left: 8px;
        }
    }
}

.select2-dropdown {
    background-color: #fff;
    border: 1px solid $input-border-color;
    border-radius: 3px;
}

.select2-container--default {
    .select2-selection--single {
        background-color: #fff;
        border: 1px solid $input-border-color;
        border-radius: 3px;

        .select2-selection__rendered {
            color: #444;
            line-height: calc(1.5em + 0.75rem + 2px);
        }

        .select2-selection__arrow {
            height: calc(1.5em + 0.75rem + 2px);
        }
    }

    .select2-selection--multiple {
        background-color: #fff;
        border: 1px solid $input-border-color;
        border-radius: 3px;

        .select2-selection__choice {
            background-color: theme-color('primary');
            border: 1px solid theme-color('primary');
            color: #fff;
            border-radius: 3px;
            padding: 2px 10px;
        }

        .select2-selection__choice__remove {
            color: #fff;
            margin-right: 8px;

            &:hover {
                color: #fff;
            }
        }
    }

    &[dir='rtl'] .select2-selection--multiple {
        .select2-selection__choice__remove {
            margin-left: 8px;
            margin-right: auto;
        }
    }

    &.select2-container--focus .select2-selection--multiple {
        border: solid $input-border-color 1px;
        outline: 0;
    }

    .select2-search--dropdown .select2-search__field {
        border: 1px solid $input-border-color;
    }

    .select2-results__option {
        &[aria-selected='true'] {
            background-color: $body-bg;
        }
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: theme-color('primary');
        color: #fff;
    }
}

.select2-container--classic {
    .select2-selection--single {
        background-color: #f7f7f7;
        border: 1px solid $input-border-color;
        border-radius: 3px;
        outline: 0;

        &:focus {
            border: 1px solid theme-color('primary');
        }

        .select2-selection__rendered {
            color: #444;
            line-height: calc(1.5em + 0.75rem + 2px);
        }

        .select2-selection__arrow {
            background-color: $body-bg;
            border-left: 1px solid $input-border-color;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            height: calc(1.5em + 0.75rem + 2px);
        }
    }

    &[dir='rtl'] .select2-selection--single {
        .select2-selection__arrow {
            border-right: 1px solid $input-border-color;
            border-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
        }
    }

    &.select2-container--open {
        .select2-selection--single {
            border: 1px solid theme-color('primary');
        }
    }

    .select2-selection--multiple {
        background-color: #fff;
        border: 1px solid $input-border-color;
        border-radius: 3px;

        &:focus {
            border: 1px solid theme-color('primary');
        }

        .select2-selection__choice {
            background-color: #fff;
            border: 1px solid $input-border-color;
            border-radius: 3px;
        }
    }

    &.select2-container--open {
        .select2-selection--multiple {
            border: 1px solid theme-color('primary');
        }
    }

    .select2-search--dropdown .select2-search__field {
        border: 1px solid $input-border-color;
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: #3875d7;
        color: #fff;
    }

    &.select2-container--open .select2-dropdown {
        border-color: theme-color('primary');
    }
}
