.ng-trigger-collapsedCard {
    .card-body {
        padding-top: 0;
    }
}

.progressbar-stack .progress,
.progressbar-stack .progress-bar {
    margin: 0 1px;
    border-radius: 3px;
}

.btn-page .btn {
    margin-right: 8px;
}

.scrollable-body {
    padding: 0 !important;

    perfect-scrollbar {
        padding: 20px 25px;
    }
}

.pill-card .nav-pills {
    padding-bottom: 15px;
}

.user-profile-list {
    table.dataTable.display tbody td,
    table.dataTable.display tbody th,
    table.dataTable.row-border tbody td,
    table.dataTable.row-border tbody th {
        border-top: none;
    }
}

.label-control {
    display: inline-flex;
    align-items: center;

    input,
    select {
        margin: 0 5px;
    }
}

.dataTables_filter,
.dataTables_length {
    label {
        display: flex;
        align-items: center;
    }

    input,
    select {
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.95rem;
        font-size: 0.875rem;
        margin: 0 5px;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: 2px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.marge-card {
    box-shadow: $card-shadow;

    .card {
        box-shadow: none;
    }

    .index-minus {
        z-index: -1;
    }
}

.rating-bar .br-horizontal {
    display: block;
}

.able-pro-accordion {
    .card-header {
        padding: 12px;

        button {
            margin: 0;
        }
    }
}

.tab-card {
    .nav-tabs {
        margin-bottom: 1rem;
    }

    .nav-pills {
        margin-bottom: 1rem;

        .nav-item {
            text-align: center;
        }
    }
}

.utility-card label {
    margin-right: 3px;
}

.users-contact {
    z-index: 1;
    position: relative;
}

.user-card .user-about-block .position-relative.d-inline-block {
    z-index: 15;
}

.user-profile .user-about-block .certificated-badge {
    z-index: 16;
}

.img-row {
    display: inline-block;
}

.img-frame {
    margin: 10px;
    border: 5px solid #fff;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

.single-image-gallery {
    .col-auto {
        padding: 0;
    }
}

.custom-range-datepicker {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;

    &.focused {
        background-color: #e6e6e6;
    }

    &.range,
    &:hover {
        background-color: $primary-color;
        color: white;
    }

    &.faded {
        background-color: rgba(2, 117, 216, 0.5);
    }
}

.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    border-radius: 0.25rem;
    display: inline-block;
    width: 2rem;

    &.focused,
    &:hover {
        background-color: #e6e6e6;
    }
}

.weekend {
    background-color: $warning-color;
    border-radius: 1rem;
    color: white;
}

.hidden {
    display: none;
}

select-dropdown .highlighted,
select-dropdown .selected.highlighted {
    background-color: $primary-color !important;
}

.table {
    td,
    th {
        font-family: $theme-font-family;
    }
}

table.dataTable {
    tbody,
    tfoot,
    thead {
        td,
        th {
            padding: 1.05rem 0.75rem;
            border-color: $theme-border;
        }
    }

    &.no-footer {
        border-color: $theme-border;
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    &.current,
    &.current:hover,
    &:hover {
        box-shadow: none;
        background: $primary-color;
        border-color: $primary-color;
        color: #fff !important;
        outline: none !important;
        border-radius: 50px;
    }
}
.basic-close {
    &:focus {
        outline: none;
    }
}

.dataTables_length select,
.dataTables_filter input {
    border: none;
    border-bottom: 1px solid #ced4da;
    background: no-repeat center bottom, center calc(100% - 1px);
    background-size: 0 100%, 100% 100%;
    transition: background 0s ease-out;

    &:focus {
        border-bottom-color: transparent;
        background-size: 100% 100%, 100% 100%;
        transition-duration: 0.3s;
        box-shadow: none;
        background-image: linear-gradient(to top, theme-color('primary') 2px, rgba(70, 128, 255, 0) 2px),
            linear-gradient(to top, #ced4da 1px, rgba(206, 212, 218, 0) 1px);
    }
}

@media only screen and (max-width: 1199px) {
    .hover-blk {
        display: none;
    }
}
