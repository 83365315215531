/**  =====================
      Radio & Checked css start
==========================  **/
.custom-checkbox {
    .custom-control-label {
        &:before {
            top: 0;
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 0.125rem;
        }

        &:after {
            content: '';
            display: block;
            width: 6px;
            height: 12px;
            opacity: 0.9;
            background-image: none;
            border-right: 2px solid #fff;
            border-top: 2px solid #fff;
            position: absolute;
            left: -21px;
            top: 10px;
            transform: scaleX(-1) rotate(135deg);
            transform-origin: left top;
        }
    }

    .custom-control-input:checked ~ .custom-control-label:before {
        animation: custripple 0.3s linear forwards;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none;
        animation: check linear 0.5s;
        opacity: 1;
    }
}

.custom-radio {
    .custom-control-label {
        &:after,
        &:before {
            top: 2px;
            width: 1.2rem;
            height: 1.2rem;
        }

        &:after {
            transform: scale(0);
            opacity: 0;
            background: #fff;
            border-radius: 50%;
            transition: all 0.3s ease;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        animation: custripple 0.3s linear forwards;
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: none;
        opacity: 1;
        transform: scale(0.4);
    }
}

.custom-switch {
    .custom-control-label {
        &:before {
            left: -2.25rem;
            top: 0.35rem;
            width: 1.75rem;
            height: 0.8rem;
            pointer-events: all;
            border-radius: 0.5rem;
            background: darken($body-bg, 5%);
            border: none;
            box-shadow: none !important;
        }

        &:after {
            top: 3px;
            left: -37px;
            width: 18px;
            height: 18px;
            border-radius: 50%;
            transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background: theme-color-level('primary', -7);
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        animation: custripple 0.3s linear forwards;
        background: theme-color('primary');
    }
}
@keyframes check {
    0% {
        height: 0;
        width: 0;
    }

    25% {
        height: 0;
        width: 6px;
    }

    50% {
        height: 12px;
        width: 6px;
    }
}
@keyframes custripple {
    0% {
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
    }

    50% {
        box-shadow: 0 0 0 12px rgba(0, 0, 0, 0.08);
    }

    100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}
//
// .checkbox {
//     padding: 10px 0;
//     min-height: auto;
//     position: relative;
//     margin-right: 5px;
//
//     input[type=checkbox] {
//         margin: 0;
//         display: none;
//         width: 22px;
//
//         + .cr {
//             padding-left: 0;
//
//             &:before {
//                 content: "\e83f";
//                 width: 22px;
//                 height: 22px;
//                 display: inline-block;
//                 margin-right: 10px;
//                 border: 2px solid #e9eaec;
//                 border-radius: 3px;
//                 font: {
//                     size: 15px;
//                     family: 'feather';
//                     weight: 400;
//                 }
//                 line-height: 19px;
//                 vertical-align: bottom;
//                 text-align: center;
//                 background: #ffffff;
//                 color: transparent;
//                 cursor: pointer;
//                 transition: all 0.2s ease-in-out;
//             }
//         }
//
//         &:checked + .cr:before {
//             background: #1dd5d2;
//             border-color: #1dd5d2;
//             color: #ffffff;
//         }
//
//         &.disabled + .cr,
//         &:disabled + .cr {
//             opacity: 0.5;
//         }
//
//         &.disabled + .cr:before,
//         &:disabled + .cr:before {
//             cursor: not-allowed;
//         }
//     }
//
//     &.checkbox-fill {
//         input[type=checkbox] {
//             + .cr {
//                 &:after {
//                     content: "";
//                     width: 22.5px;
//                     height: 22.5px;
//                     display: inline-block;
//                     margin-right: 10px;
//                     border: 2px solid #e9eaec;
//                     border-radius: 2px;
//                     vertical-align: bottom;
//                     text-align: center;
//                     background: transparent;
//                     cursor: pointer;
//                     transition: all 0.2s ease-in-out;
//                     position: absolute;
//                     top: 8.5px;
//                     left: 3px;
//                 }
//
//                 &:before {
//                     opacity: 0;
//                     content: "\e840";
//                     font-size: 27px;
//                     background: transparent;
//                 }
//             }
//
//             &:checked {
//                 + .cr {
//                     &:after {
//                         opacity: 0;
//                     }
//
//                     &:before {
//                         opacity: 1;
//                         background: transparent;
//                         color: #1dd5d2;
//                         border-color: transparent;
//                     }
//                 }
//             }
//         }
//     }
//     @each $value in $color-bt-name {
//         $i: index($color-bt-name, $value);
//
//         &.checkbox-#{$value} input[type=checkbox]:checked + .cr:before {
//             background: nth($color-bt-color,$i);
//             border-color: nth($color-bt-color,$i);
//             color: #ffffff;
//         }
//
//         &.checkbox-fill.checkbox-#{$value} input[type=checkbox]:checked + .cr:before {
//             background: transparent;
//             color: nth($color-bt-color,$i);
//             border-color: transparent;
//         }
//     }
//
//     .cr {
//         cursor: pointer;
//     }
// }
//
// .radio {
//     padding: 10px 0;
//     min-height: auto;
//     position: relative;
//     margin-right: 5px;
//
//     input[type=radio] {
//         margin: 0;
//         display: none;
//         width: 22px;
//
//         + .cr {
//             padding-left: 0;
//
//             &:after,
//             &:before {
//                 content: "";
//                 display: inline-block;
//                 margin-right: 10px;
//                 border-radius: 50%;
//                 vertical-align: bottom;
//                 background: #fff;
//                 color: transparent;
//                 cursor: pointer;
//                 transition: all 0.2s ease-in-out;
//             }
//
//             &:before {
//                 width: 22px;
//                 height: 22px;
//                 border: 2px solid #e9eaec;
//             }
//
//             &:after {
//                 width: 12px;
//                 height: 12px;
//                 position: absolute;
//                 top: 12px;
//                 left: 5px;
//                 @supports (-webkit-overflow-scrolling: touch) {
//                     top: 13px;
//                 }
//                 @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
//                     top: 5px;
//                 }
//             }
//         }
//
//         &:checked {
//             + .cr {
//                 &:before {
//                     border-color: $primary-color;
//                 }
//
//                 &:after {
//                     background: $primary-color;
//                 }
//             }
//         }
//
//         &:disabled {
//             + .cr {
//                 opacity: 0.5;
//                 cursor: not-allowed;
//
//                 &:after,
//                 &:before {
//                     cursor: not-allowed;
//                 }
//             }
//         }
//     }
//
//     &.radio-fill {
//         input[type=radio] {
//             + .cr {
//                 &:after {
//                     width: 18px;
//                     height: 18px;
//                     top: 9px;
//                     left: 2px;
//                     @supports (-webkit-overflow-scrolling: touch) {
//                         top: 10px;
//                     }
//                     @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
//                         top: 2px;
//                     }
//                 }
//             }
//         }
//     }
//     @each $value in $color-bt-name {
//         $i: index($color-bt-name, $value);
//
//         &.radio-#{$value} input[type=radio]:checked + .cr {
//             &:before {
//                 border-color: nth($color-bt-color,$i);
//             }
//
//             &:after {
//                 background: nth($color-bt-color,$i);
//             }
//         }
//     }
//
//     .cr {
//         cursor: pointer;
//     }
// }
// @-moz-document url-prefix() {
//     .radio input[type="radio"] + .cr::after {
//         top: 12px;
//     }
// }
//
// .form-group.d-inline {
//     .radio {
//         input[type=radio] {
//             + .cr {
//                 &:after {
//                     @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
//                         top: 6px;
//                     }
//                 }
//             }
//         }
//
//         &.radio-fill {
//             input[type=radio] {
//                 + .cr {
//                     &:after {
//                         @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
//                             top: 3px;
//                         }
//                     }
//                 }
//             }
//         }
//     }
// }
//
// .custom-controls-stacked {
//     .radio {
//         input[type=radio] {
//             + .cr {
//                 &:after {
//                     top: 15px;
//                 }
//             }
//         }
//     }
// }
/**====== Radio & Checked css end ======**/
